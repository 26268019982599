export default {
    id: 'lifjot',
    debug: false,
    app: 'orderadmin',
    title: 'Lifjot',
    domain: 'lifjot',
    localServer: 'lifjot.oawms.com',
    defaultServer: 'https://pro.oawms.com',
    securityLogo: 'assets/logolifjotoa-black.png',
    logoSrc: 'assets/logolifjotoa-blacksquare.ico',
    logoLeft: 'assets/logolifjotoa-black.png',
    logoTop: 'assets/logolifjotoa-black.png',
    theme: 'default',
    formats: {
        internationalDate: 'LLL',
        date: 'MM/DD/YYYY',
        fullDate: 'MM/DD/YYYY hh:mm A z',
        defaultTimezone: 'America/New_York'
    },
    servers: [],
    locale: {
        name: 'English',
        locale: 'en',
        flag: 'us'
    },
    automaticCreatedEntities: {
        lifjot: {
            sender: {},
            shop: {}
        }
    },
    defaults: {
        addressFormat: 'international',
        order: {
            paymentState: 'paid'
        }
    },
    packagingSettings: {
        acceptanceControl: false,
        consumptionControl: false,
        weightControl: true,
        maxWeight: 14000,
        consumption: null,
        checkParcelContent: false,
        trustIncomingMeasurements: true,
        setPredefinedMeasurements: false,
        isEnabledDeliveryRequestTable: false,
        default: {
            weight: 1,
            width: 10,
            length: 10,
            height: 10,
            sendDate: null
        },
        printing: {
            interceptAutoClose: false,
            autoClosePrintWindow: true,
            modal: false,
            timeout: 4500
        }
    },
    defaultDimensions: {
        weight: 1,
        dimensions: {
            x: 10,
            y: 10,
            z: 10
        },
        weightUnit: 'lb',
        dimensionUnit: 'in'
    }
}
