export default {
  id: 'app.oawms',
  debug: false,
  title: 'OrderAdmin',
  domain: '',
  localServer: 'app.oawms.com',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/robot.png',
  logoSrc: 'assets/logos/logo.png',
  logoLeft: 'assets/logos/logo-nosign.png',
  logoTop: 'assets/logos/logo-nosign.png',
  theme: 'default',
  servers: [],
  domains: [
    {
      name: 'Global',
      domain: '',
      host: 'https://pro.oawms.com'
    },
    {
      name: 'EU',
      domain: '',
      host: 'https://panel.orderadmin.eu'
    }
  ],
  wizardTabs: {
    warehouse: {
      state: 'active',
      type: 'virtual'
    }
  }
}
