export default {
  id: 'app-dev',
  debug: false,
  title: 'OrderAdmin',
  app: 'orderadmin',
  domain: '',
  localServer: 'app-dev.orderadmin.ru',
  defaultServer: 'https://alpha.orderadmin.eu',
  securityLogo: 'assets/robot.png',
  logoSrc: 'assets/logos/logo.png',
  logoLeft: 'assets/logos/logo-nosign.png',
  logoTop: 'assets/logos/logo-nosign.png',
  googleApiKey: 'AIzaSyAjsWnMXpwZKAMMVENXoGNuTLDHsvBACVg',
  theme: 'default',
  automaticCreatedEntities: {
    all: {
      sender: {},
      shop: {}
    }
  },
  servers: [
    {
      name: 'Global',
      domain: '',
      host: 'https://pro.oawms.com'
    },
    // {
    //   name: 'RU',
    //   domain: '',
    //   host: 'https://panel.orderadmin.ru'
    // },
    {
      name: 'EU',
      domain: '',
      host: 'https://panel.orderadmin.eu'
    },
    {
      name: 'Beta',
      domain: '',
      host: 'https://beta.orderadmin.ru'
    },
    {
      name: 'Alpha',
      domain: '',
      host: 'https://alpha.orderadmin.eu'
    },
    {
      name: 'Dev',
      domain: '',
      host: 'https://dev.orderadmin.ru'
    },
    {
      name: 'RW',
      domain: 'rw',
      host: 'https://rw.orderadmin.ru'
    }
  ]
}
